.checklist-answer-radio .ant-radio-wrapper {
    align-items: center;
    
}

.checklist-answer-radio .ant-radio {
    margin-bottom: 5px;
}

.checklist-answer-radio .ant-radio-checked {
    margin-bottom: 5px;
}

.checklist-answer-radio .ant-checkbox-wrapper {
    align-items: center;
}

.checklist-answer-radio .ant-checkbox {
    margin-bottom: 5px;
}

.checklist-answer-radio .ant-checkbox-checked {
    margin-bottom: 5px;
}