.radio-red .ant-radio-checked .ant-radio-inner{
    border-color: red !important ;
  }
  
  .radio-red .ant-radio-checked .ant-radio-inner:after{
    background-color: red;
  }
  
  .radio-red .ant-radio:hover .ant-radio-inner {
    border-color: red ;
  }