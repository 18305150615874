@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .login-input {
    @apply block border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-indigo-400 rounded-md;
  }
  .login-btn {
    @apply mt-4 !bg-indigo-500 text-white py-2 px-6 rounded-md hover:!bg-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-700 disabled:hover:clear-none;
  }
}
