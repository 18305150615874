@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .listing-table-header {
    @apply bg-tableHeaderColor text-white hover:bg-blue-500;
  }
  .listing-table-row {
    @apply !leading-[50px] !border-t-0 !font-medium !text-[12px] !text-[#2E3B52];
  }
}

.ag-theme-material {
  --ag-header-cell-hover-background-color: theme('colors.colorPrimary');
}

.ag-theme-material .ag-header-icon {
  color: theme('colors.gray.300') !important;
}

.ag-header {
  border: 0 !important;
}

.product-table-header {
  background: #d4e4ff;
  font-weight: bold;
  display: block;
  color: black;
  white-space: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}

.product-table-header > .ag-cell-label-container > .ag-header-cell-label .ag-header-cell-text {
  white-space: pre-wrap !important;
}

.my-table-section {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #2e3b52;
  background: white;
  text-align: center;
  border-width: 0 !important;
}

.my-table-section-hr-left {
  margin-top: 12px;
  margin-left: 30px;
}

.my-table-section-hr-right {
  margin-top: 12px;
  margin-right: 30px;
}

.bold-cell {
  font-weight: bold;
  font-size: 15px;
}

.product-request-blue-cell-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.product-request-table .ag-theme-material .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 0px;
}
