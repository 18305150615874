.checklist-confirm-modal .ant-modal-content {
    border-radius: 16px;
    height: 220px;
  }
  
  .checklist-confirm-modal .ant-modal-confirm-title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 28px !important;
    line-height: 36px !important;
  
    color: #2c2738 !important;
  }
  
  .checklist-confirm-modal .ant-modal-confirm-content {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 21px !important;
  
    color: #2c2738 !important;
  }
  
  .checklist-confirm-modal .ant-modal-confirm-btns {
    margin-top: 34px !important;
  }
  
  .checklist-confirm-modal-cancel-button {
    border: 2px solid #7c9cbf !important;
    border-radius: 6px !important;
  
    width: 140px !important;
    height: 48px !important;
  
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    text-align: center !important;
    color: #7c9cbf !important;
  }
  
  .checklist-confirm-modal-ok-button {
    background: theme('colors.colorPrimary') !important;
    border-radius: 6px !important;
    border: none !important;
    color: white !important;
  
    width: 140px !important;
    height: 48px !important;
  
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    text-align: center !important;
  }
  
  .checklist-confirm-modal-ok-button:hover {
    background: theme('colors.tableHeaderColor') !important;
  }
