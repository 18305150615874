.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(34, 34, 34, 0.411);
    z-index: 10;
  }
  
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #000;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  
  .loader_title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 80px;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  
  